import { IconDefinition, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";

export type Animation = "spin";

export interface FlumeIconProps extends FontAwesomeIconProps {
  animation?: Animation;
  className?: string;
  encircled?: boolean;
  encircledClassName?: string;
  encircledTheme?: "neutral" | "primary" | "success" | "warning" | "error";
  fixedWidth?: boolean;
  icon: IconDefinition;
  size?: SizeProp;
  transform?: string;
}
export const FlumeIcon = forwardRef<HTMLElement, FlumeIconProps>(
  (
    {
      animation,
      className = "",
      encircled = false,
      encircledClassName = "",
      encircledTheme = "neutral",
      fixedWidth = false,
      icon,
      size,
      transform = "",
      ...props
    },
    ref
  ) => {
    const classes = [
      encircled
        ? `encircled encircled-${size} encircled-theme-${encircledTheme} ${encircledClassName}`
        : "",
      className,
    ].reduce((prev, curr) => (curr ? prev + `${prev ? " " : ""}${curr}` : prev), "");
    return (
      <FontAwesomeIcon
        icon={icon}
        size={size}
        fixedWidth={fixedWidth}
        spin={animation === "spin"} // spin is the only animation we currently use
        className={classes}
        data-testid={`icon-${icon.iconName}`}
        {...props}
      ></FontAwesomeIcon>
    );
  }
);
