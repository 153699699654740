import { DateTime } from "luxon";
import { FleetCard } from "../../generated/openapi";
import { CardStatus, SuspensionStatus } from "./type";

export const fleetCardToUI = (card: FleetCard) => ({
  ...card,
  profile: { ...card.profile, spend_per_day: card.profile?.spend_per_day / 100 },
});

export const fleetCardToAPI = (card: FleetCard) => card;

export const maskCreditCardNumber = (ccn: string, maskType: string = "*") => {
  return ccn
    ? `${maskType.repeat(3)}${ccn.substring(Math.max(ccn.length - 5, 0), ccn.length)}`
    : "";
};

/**
 * Cleans a business name for embossing. Limits to 20 characters and removes special characters.
 * @param name Business name to clean
 * @returns the cleaned business name.
 */
export const cleanCompanyName = (name: string) => {
  return name.replace(/[^0-9a-zA-Z\s]/g, "").slice(0, 20);
};

export const shouldPayFeeForCard = (status: CardStatus | string) =>
  status !== CardStatus.SUSPENDED && status !== CardStatus.TERMINATED;

export const getFleetSuspensionStatus = (suspendedAt: string | null): SuspensionStatus => {
  const suspensionDateUTC = DateTime.fromISO(suspendedAt);
  const currentDateUTC = DateTime.now();
  const daysSinceSuspension = currentDateUTC.diff(suspensionDateUTC, "days").days;

  if (daysSinceSuspension >= 120) {
    return SuspensionStatus.PAST_DUE_STRIKE_3;
  } else if (daysSinceSuspension >= 90) {
    return SuspensionStatus.PAST_DUE_STRIKE_2;
  } else if (daysSinceSuspension >= 10) {
    return SuspensionStatus.PAST_DUE_STRIKE_1;
  } else {
    return SuspensionStatus.NONE;
  }
};
